import { defineModule } from '../utils/helpers';

const getElements = () => ({
  messageButton: document.getElementById(
    'private-message-button',
  ) as HTMLButtonElement,

  form: document.getElementById('private-message-form') as HTMLDivElement,

  closeButton: document.getElementById(
    'close-private-message',
  ) as HTMLButtonElement,
});

export default defineModule(() => {
  const { messageButton, closeButton, form } = getElements();
  let intervalId: NodeJS.Timeout | undefined;

  function hideFormAndCleanup() {
    if (form) {
      form.style.display = 'none';
      sessionStorage.removeItem('gf_private_message_submitted');
      if (intervalId) {
        clearInterval(intervalId);
        intervalId = undefined;
      }
    }
  }

  function checkFormSubmission() {
    if (sessionStorage.getItem('gf_private_message_submitted')) {
      setTimeout(hideFormAndCleanup, 5000);
      return true;
    }
    return false;
  }

  function startFormSubmissionCheck() {
    if (!checkFormSubmission()) {
      intervalId = setInterval(() => {
        if (checkFormSubmission()) {
          clearInterval(intervalId);
        }
      }, 5000);
    }
  }

  form?.addEventListener('submit', () => {
    startFormSubmissionCheck();
  });

  messageButton.addEventListener('click', () => {
    form?.classList.remove('hidden');
    form?.classList.add('grid');
    form?.scrollIntoView({ behavior: 'smooth' });
  });

  closeButton?.addEventListener('click', () => {
    form?.classList.add('hidden');
    form?.classList.remove('grid');
  });
});
